import React from "react"
import { graphql } from "gatsby"

import MainLayout from "../layouts/MainLayout"
import Seo from "../components/Seo"
import GalleryComponent from "../components/Gallery"
import PageHeader from "../components/PageHeader"
import CardsWithSwiper from "../components/CardsWithSwiper"
import SocialLinks from "../components/SocialLinks"
import ReactMarkdown from "../components/ReactMarkdown"
import { Link } from "gatsby"

export default function BlogPost({ data, pageContext }) {
  const { locale } = pageContext

  const images =
    data.strapiBlogPost &&
    data.strapiBlogPost.gallery &&
    data.strapiBlogPost.gallery.map(
      picture => picture.localFile.childImageSharp
    )

  return (
    <MainLayout alternativeSlug={data.strapiBlogPost.localizations.data?.[0]?.attributes?.slug} locale={locale}>
      <Seo
        title={`${data.strapiBlogPost.seo.title}`}
        description={`${data.strapiBlogPost.seo.description}`}
      />

      <PageHeader
        image={data.strapiBlogPost.header.image}
        header={data.strapiBlogPost.header.title}
        detail={data.strapiBlogPost.header.description}
        buttons={data.strapiBlogPost.header.buttons}
        bannerBg={data.strapiBlogPost.tag.backgroundColor}
      />

      <div className="container page-content">
        <aside className="page-content-menu donation">
          <h3>{data.strapiBlogPost.card.header}</h3>
          <p>{data.strapiBlogPost.card.content}</p>

          {data.strapiBlogPost.card.buttons.map(button => (
            <div>
              <Link
                to={button.link}
                className="btn btn-primary"
                style={{
                  color: button.textColor,
                  backgroundColor: button.backgroundColor,
                }}
                onMouseEnter={e => {
                  e.target.style.color = button.textHoverColor
                  e.target.style.backgroundColor = button.backgroundHoverColor
                }}
                onMouseLeave={e => {
                  e.target.style.color = button.textColor
                  e.target.style.backgroundColor = button.backgroundColor
                }}
              >
                {button.text}
              </Link>

              <br />
              <br />
            </div>
          ))}

          <ReactMarkdown
            children={data.strapiBlogPost.card.subContent.data}
            className="ck-content"
          />
          <SocialLinks />
        </aside>

        <ReactMarkdown
          children={data.strapiBlogPost.content.data.content}
          className="ck-content page-content-text"
        />
      </div>
      {images && (
        <GalleryComponent
          pictures={images}
          backgroundColor={data.strapiBlogPost.tag.backgroundColor}
        />
      )}

      <CardsWithSwiper nodes={data.secondRow.nodes.sort((a, b) => {
        // Prioritize pinned posts
        if (a.pin && !b.pin) return -1;
        if (!a.pin && b.pin) return 1;

        // If both dates are null or both are pinned, sort based on createdAt
        if ((!a.date && !b.date) || (a.pin && b.pin)) return new Date(b.createdAt) - new Date(a.createdAt);

        // If only one date is null, place it at the end
        if (!a.date) return 1;
        if (!b.date) return -1;

        // If neither date is null and neither are pinned, sort based on date first, then createdAt
        const dateDiff = new Date(b.date) - new Date(a.date);
        if (dateDiff !== 0) return dateDiff;

        return new Date(b.createdAt) - new Date(a.createdAt);
      })} />
    </MainLayout>
  )
}

export const blogPostQuery = graphql`
  query ($slug: String!, $secondBlogTag: String!) {
    strapiBlogPost(slug: { eq: $slug }) {
      slug
      content {
        data {
          content
        }
      }
      header {
        title
        buttons {
          textColor
          textHoverColor
          backgroundColor
          backgroundHoverColor
          link
          text
        }
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      localizations {
        data {
          attributes {
            slug
          }
        }
      }
      seo {
        description
        title
        keywords
      }
      tag {
        backgroundColor
        locale
        name
        id
      }
      gallery {
        localFile {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 285
              height: 161
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      card {
        id
        header
        showSocialMediaIcons
        content
        buttons {
          text
          link
          textColor
          textHoverColor
          backgroundColor
          backgroundHoverColor
        }
        subContent {
          data
        }
      }
    }
    secondRow: allStrapiBlogPost(
      filter: { tag: { id: { eq: $secondBlogTag } } }
      sort: { fields: createdAt, order: DESC }
      limit: 8
    ) {
      nodes {
        id
        slug
        header {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        date
        pin
        tag {
          name
        }
      }
    }
  }
`
